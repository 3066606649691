import API from "@/server/api";
import { desCode } from "@/utils/encryption.js";
import Router from "@/router/index.js";
const state = {
  mobile: "",
};

const getters = {
  mobile: (state) => state.mobile,
};

const mutations = {
  setMobile(state, mobile) {
    state.mobile = mobile;
  },
};
const actions = {
  // APP 登录
  async appLogin({ commit }, phone) {
    try {
      const form = {
        phone,
        code: desCode.appEncrypt3Des(phone),
      };
      const result = await API.appH5Login(form);
      if (result.success) {
        const { userId, state } = result.data;
        console.log(userId, state);
        localStorage.setItem("userId", JSON.stringify(userId));
        localStorage.setItem("stock", state);
        localStorage.setItem("env", "APP");
        localStorage.setItem("cellPhone", phone * 1);
        localStorage.setItem("formCachingInfo", JSON.stringify({}));
        localStorage.setItem("isItCached", JSON.stringify(false));
        Router.replace("/");
      }
    } catch (e) {
      console.log(e, "错误信息");
      return e;
    }
  },
  async login({ commit }, user) {
    try {
      user.channel = "BLMX_H5";
      const result = await API.isLogin(user);
      if (result.success) commit("setMobile", user.phone);
      return result;
    } catch (e) {
      console.log(e, "错误信息h");
      return e;
    }
  },
  // 设置用户
  setMobile({ commit }, user) {
    commit("setMobile", user.phone);
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
