const CryptoJS = require("crypto-js");
const key = "fymxhtmlkey7758258888888";
export const desCode = {
  ncrypted: (plaintText) => {
    console.log(typeof plaintText, plaintText);
    const keyStr = CryptoJS.enc.Utf8.parse(key);
    const encryptedData = CryptoJS.TripleDES.encrypt(plaintText, keyStr, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encryptedData.toString();
  },
  // 3DES解密
  decrypted: (encryptedStr) => {
    const keyStr = CryptoJS.enc.Utf8.parse(key);
    const decryptedData = CryptoJS.TripleDES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(encryptedStr) },
      keyStr,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    // 解密后，需要按照Utf8的方式将明文转位字符串
    return decryptedData.toString(CryptoJS.enc.Utf8);
  },
  // 3DES加密
  appEncrypt3Des: (text) => {
    // 将密钥转换为UTF-8编码
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    // 进行3DES加密，使用ECB模式和Pkcs7填充
    const encrypted = CryptoJS.TripleDES.encrypt(text, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    // 返回加密后的Base64字符串
    return encrypted.toString();
  },
  // //des加密
  encryptDes: (text) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.DES.encrypt(text, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  },
  //解密
  decryptDes: (ciphertext) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const decrypted = CryptoJS.DES.decrypt(
      {
        ciphertext: CryptoJS.enc.Base64.parse(ciphertext),
      },
      keyHex,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  },
};
