const state = {
  formCachingInfo: {}
};

const getters = {
  formCachingInfo: state => state.formCachingInfo
};

const mutations = {
  setFormCachingInfo(state, params) {
    console.log(params, "--params--");
    state.formCachingInfo = params;
    console.log(state.formCachingInfo, "--state.formCachingInfo--");
  }
};
const actions = {
  // 设置缓存信息
  setFormCaching({ commit }, params) {
    commit("setFormCachingInfo", params);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
