import { createApp } from "vue";
import APP from "./App.vue";
import Vant from "vant";
import router from "@/router";
import store from "@/store";
import filters from "@/filters";
import "./assets/normalize.css";
import "./assets/layout.less";
import "vant/lib/index.css";
import "./assets/common.css";
const app = createApp(APP);
app
  .use(store)
  .use(router)
  .use(Vant)
  .mount("#app");
// 全局属性
app.config.globalProperties.$filters = filters;
